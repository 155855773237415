export function getLang({ onlyUsingLanguageArr, defaultLang, }: { onlyUsingLanguageArr: Lang[]; defaultLang: Lang } = { onlyUsingLanguageArr: [], defaultLang: 'en' }): Lang {
  let lang =
    document.documentElement.getAttribute('lang')?.replace(/-.*$/, '') ||
    defaultLang;
  let langs: Lang;

  // html의 lang 속성이 허용된 언어안에 있는지 확인
  if (isPermitLanguage(onlyUsingLanguageArr, lang)) {
    langs = lang;
  } else {
    langs = defaultLang
  }
  return langs;
}

export function getLangForL10n({ onlyUsingLanguageArr, defaultLang, }: { onlyUsingLanguageArr: Lang[]; defaultLang: Lang } = { onlyUsingLanguageArr: [], defaultLang: 'en' }): Lang {
  let htmlLang =
    document.documentElement.getAttribute('lang')?.replace(/-.*$/, '') ||
    defaultLang;
  const htmlDataCountry = document
    .querySelector('html')
    ?.getAttribute('data-country')
    ?.toLowerCase();
  const userDataLocale = window.userData?.locale?.toLowerCase();

  /**
  * "스페인어-유럽" 과, "스페인어-남미" 의 언어코드가 다르다.
  * 언어는 같은데(es) locale 값이 다르고(es-ES, es-MX), locale 값에 따라 사용되는 번역이 다르다.
  * 그래서 기획과 백엔드와 프론트가 아래와 같이 협의를 진행하였다.
  *
  * 1) html lang 속성에는 기존과 같이 lang 값만 줄것이다. (스페인어 인경우 무조건 es)
  * 2) 단, es 만 가지고, l10n에 es_ES을 사용할지, ex_MX를 쓸지 모르므로,
  * 3) 처음에 cnb 에서 불러오는 userData.locale을 통해 분기를 태우고
  * 4) cnb 가 없는 페이지의 경우도 있으니,  data-country를 통해 분기를 태운다.
  *
  * [TODO] 중국어-간체 가 추가될때도 비슷하게 진행될것 같다.
  */
  if (htmlLang === 'es') {
    // 1) userData 먼저 보고
    if (userDataLocale) {
      if ((/-es$/i).test(userDataLocale)) {
        htmlLang = 'es_ES';
      } else {
        htmlLang = 'es_MX';
      }
      // 2) dataCountry 보고
    } else if (htmlDataCountry) {
      if ((/^es$/i).test(htmlDataCountry)) {
        htmlLang = 'es_ES';
      } else {
        htmlLang = 'es_MX';
      }
      // 3) 둘다 없으면
    } else {
      htmlLang = 'es_ES';
    }
  }


  let langs: Lang;

  // html의 lang 속성이 허용된 언어안에 있는지 확인
  if (isPermitLanguage(onlyUsingLanguageArr, htmlLang)) {
    langs = htmlLang;
  } else {
    langs = defaultLang
  }
  return langs;
}

/**
 * settingLayer의 언어 선택
 *
 * 왜? settingLayer의 언어는 getLangForL10n 를 사용하지 않고 (html의 lang속성 값을 보지 않고)
 * 패스의 내용을 봐야 하는 것인가.
 *
 * 일단, settingLayer는 www.plaync.com/policy/cookiepolicy 페이지에서만 사용핟다는 전제조건이 있다.
 * (위 조건이 깨지면, 아래의 언어선택 로직은 사용할수 없음)
 *
 * (관련 부서에서 말씀해주신 내용)
 * cookiepolicy 페이지에는 path 페이지내의 "쿠키 정책 내용"이 일본어, 독일어, 영어로 출력 되게 만들었다.
 * 문제는 "쿠키 정책 내용"의 언어와 html의 lang속성이 불일치 한다는 것이다.
 * 즉, 페이지 자체의 cnb 뭐 이런건, lang속성을 따라가지만  "쿠키 정책 내용"의 언어는 lang속성을 따라가지 않고 path에 의존 한다는 내용이다.
 * (이건 좀...)
 *
 * 여튼 그래서, ncui 의 settingLayer 의 출력 언어도 path를 따라 가야 한다. *
 */
export function getLangForL10nSettingLayer(): Lang {

  // 독일어 페이지인경우  - https://www.plaync.com/policy/cookiepolicy/de?showLayer=true
  if (/\/de\/?$/.test(location.pathname)) {
    return 'de';
    //  일본어인 경우 - https://www.plaync.com/policy/cookiepolicy/ja?showLayer=true
  } else if (/\/ja\/?$/.test(location.pathname)) {
    return 'ja'
  } else {
    return 'en'
  }
}

function isPermitLanguage(onlyUsingLanguageArr: Lang[], lang: string): lang is Lang {
  if (onlyUsingLanguageArr.length > 0) {
    return onlyUsingLanguageArr.includes(lang as Lang);
  } else {
    return true;
  }
}

export function getCountry({ onlyUsingCountry, defaultCountry, }: { onlyUsingCountry?: string[]; defaultCountry: string } = { defaultCountry: 'KR', }): string {
  //   let country = $('html').attr('data-country') || defaultCountry;
  let country = document.documentElement.dataset.country || defaultCountry;

  // html의 data-country 속성이 허용된 국가안에 있는지 확인
  if (onlyUsingCountry && onlyUsingCountry.length > 0 && !onlyUsingCountry.includes(country)) {
    country = defaultCountry;
  }

  return country;
}

export function getPlayncDomain(): string {
    let env = getSeviceEnv();
    let url = '';
    switch (env) {
        case 'opdev':
            url = 'https://rc-www.plaync.com';
            break;
        case 'sandbox':
            url = 'https://rc-www.plaync.com';
            break;
        case 'rc':
            url = 'https://rc-www.plaync.com';
            break;
        default:
            url = 'https://www.plaync.com';
            break;
    }
    return url;
}

export function getNCACookieDomain(): string {
  let env = getSeviceEnv();
  let url = '';
  switch (env) {
      case 'opdev':
          url = 'https://qa-cookie';
          break;
      case 'sandbox':
          url = 'https://qa-cookie';
          break;
      case 'rc':
          url = 'https://cookie';
          break;
      default:
          url = 'https://cookie';
          break;
  }
  return url;
}

export function getWstaticUrlObjectStorage(): string {
  let env = getSeviceEnv();
  let url = '';
  switch (env) {
    case 'opdev':
      url = 'https://op-assets.ncsoft.com';
      break;
    case 'sandbox':
      url = 'https://sb-assets.plaync.com';
      break;
    case 'rc':
      url = 'https://rc-assets.playnccdn.com';
      break;
    default:
      url = 'https://assets.playnccdn.com';
      break;
  }
  return url;
}

export function getWstaticUrl(): string {
  let env = getSeviceEnv();
  let url = '';
  switch (env) {
    case 'opdev':
      url = 'https://op-wstatic.ncsoft.com';
      break;
    case 'sandbox':
      url = 'https://sb-static.plaync.com';
      break;
    case 'rc':
      url = 'https://rc-wstatic.plaync.co.kr';
      break;
    default:
      url = 'https://wstatic-cdn.plaync.com';
      break;
  }
  return url;
}

export const getSeviceEnv = (): string => {
  if (/^(rc-tw.)/.test(location.hostname)) {
    return 'rc-tw';
  } else if (/^(tw.)/.test(location.hostname)) {
    return 'tw';
  } else if (/^(rc\.|rc-)/.test(location.hostname)) {
    return 'rc';
  } else if (/^opdev/.test(location.hostname)) {
    return 'opdev';
  } else if (/^op-/.test(location.hostname)) {
    return 'opdev';
  } else if (/^op./.test(location.hostname)) {
    return 'opdev';
  } else if (/^sb-|sb\./.test(location.hostname)) {
    return 'sandbox';
  } else if (/^local/.test(location.hostname)) {
    return 'opdev';
  } else {
    return 'live';
  }
};

export function getContiApiLocale(lang: string): string {
  let result = '';
  switch (lang) {
    case 'ar':
      result = 'ar-AE';
      break;
    case 'en':
      result = 'en-US';
      break;
    case 'id':
      result = 'id-ID';
      break;
    case 'ja':
      result = 'ja-JP';
      break;
    case 'ko':
      result = 'ko-KR';
      break;
    case 'th':
      result = 'th-TH';
      break;
    case 'zh':
      result = 'zh-TW';
      break;
    case 'ru':
      result = 'ru-RU';
      break;
    default:
      result = 'en-US';
      break;
  }
  return result;
}

export function getPlayncContiDomain(): string {
  let env = getSeviceEnv();
  let domain = '';
  switch (env) {
    case 'opdev':
      domain = 'https://rc-www.plaync.com';
      break;
    case 'sandbox':
      domain = 'https://rc-www.plaync.com';
      break;
    case 'rc':
      domain = 'https://rc-www.plaync.com';
      break;
    default:
      domain = 'https://www.plaync.com';
      break;
  }
  return domain;
}

/**
 * [PSD-12190] [CSDT-2346]
 */
export function isSDKApp(){;
  const UA_SDK = /ncmop/i;

  return UA_SDK.test(window.navigator.userAgent);
}


/**
 * [PSD-12190] [CSDT-2346]
 */
export function isLandscapeMode(){
  if (window.innerWidth > window.innerHeight) {
    return true;
  } else {
    return false;
  }
}

