const l10n: I18NCookieSetLayerTextData = {

	"confirmToast":{
    "content": "NCSOFT использует информацию пользователей, соблюдая меры безопасности.<br>NCSOFT использует файлы куки и иные функции отслеживания в целях повышения функциональности сайта, предоставления персонализированной рекламы, анализа трафика, а также ведения статистики пользователей.<br>Нажав «Разрешить все», вы соглашаетесь с использованием всех файлов куки. В случае нажатия «Запретить все», будут сохраняться только обязательные файлы куки.<br>Вы можете в любое время ознакомиться с дополнительной информацией, а также изменить или отменить согласие с использованием файлов куки в разделе <a href=\"#\">Политика использования Cookies</a>",
    "BtnApply": "Разрешить все",
    "BtnDeny": "Запретить все",
		"BtnApplyOptional": "Согласие (не обязательно)",
    "BtnSetting": "Настройки"
  },
  "settingModal":{
    "msgSuccess": "",
    "titleLayer": "",
    "topDesc": "",
    "chkRequired": "",
    "chkOptional": "",
    "chkOptional2": "",
    "chkOptional3": "",
    "btnApply": "",
    "tabRequired": "",
    "tabOptional": "",
    "tabOptional2": "",
    "tabOptional3": "",
    "requiredDesc": "",
    "optionalDesc": "",
    "optional2Desc": "",
    "optional3Desc": "",
    "providerColum": "",
    "nameColum": "",
    "purposeColum": "",
    "dataColum": "",
    "typeColum": ""
  }
}
export default l10n;
