const l10n: I18NCookieSetLayerTextData = {
	"confirmToast":{
    "content": "NCSOFT mengelola informasi pribadi Anda dengan aman.<br>Kami menggunakan cookie dan teknologi pelacakan lainnya untuk senantiasa meningkatkan kinerja situs web dan memberikan iklan yang sesuai dengan preferensi Anda. Kami menyimpan informasi yang berkaitan dengan Anda untuk tujuan analisis lalu lintas dan statistik pengunjung, guna menyediakan layanan yang lebih baik.<br>Dengan memilih \"Setujui Semua\", Anda menyetujui penyimpanan cookie di perangkat Anda. Klik \"Tolak Semua\" untuk menolak penyimpanan semua cookie kecuali cookie wajib.<br>Anda dapat melihat rincian dan mengubah persetujuan kapan saja melalui <a href=\"#\">Kebijakan Cookie</a>.",
    "BtnApply": "Setujui Semua",
    "BtnDeny": "Tolak Semua",
		"BtnApplyOptional": "Setujui Pilihan",
    "BtnSetting": "Pengaturan"
  },
  "settingModal":{
    "msgSuccess": "",
    "titleLayer": "",
    "topDesc": "",
    "chkRequired": "",
    "chkOptional": "",
    "chkOptional2": "",
    "chkOptional3": "",
    "btnApply": "",
    "tabRequired": "",
    "tabOptional": "",
    "tabOptional2": "",
    "tabOptional3": "",
    "requiredDesc": "",
    "optionalDesc": "",
    "optional2Desc": "",
    "optional3Desc": "",
    "providerColum": "",
    "nameColum": "",
    "purposeColum": "",
    "dataColum": "",
    "typeColum": ""
  }
}
export default l10n;
