const l10n: I18NCookieSetLayerTextData = {

	"confirmToast":{
    "content": "NCSOFT cam kết bảo đảm an toàn cho thông tin cá nhân của bạn.<br>Chúng tôi sử dụng Cookie và các công nghệ truy vết khác để nâng cấp tính năng và gửi những thông tin quảng cáo được cá nhân hóa, đồng thời lưu trữ thông tin khách hàng nhằm phục vụ cho việc phân tích dữ liệu và thống kê lượt truy cập, hướng đến mục tiêu không ngừng cải thiện chất lượng dịch vụ.<br>Nhấn \"Đồng ý tất cả\" đồng nghĩa với việc cho phép lưu trữ Cookie vào thiết bị của bạn, \"Từ chối tất cả\" đồng nghĩa với việc chỉ lưu Cookie bắt buộc.<br><a href=\"#\">Chính sách Cookie</a> cho phép kiểm tra nội dung chi tiết cũng như thay đổi hoặc thu hồi quyết định trên bất cứ lúc nào.",
    "BtnApply": "Đồng ý tất cả",
    "BtnDeny": "Từ chối tất cả",
		"BtnApplyOptional": "Đồng ý một số",
    "BtnSetting": "Cài đặt"
  },
  "settingModal":{
    "msgSuccess": "",
    "titleLayer": "",
    "topDesc": "",
    "chkRequired": "",
    "chkOptional": "",
    "chkOptional2": "",
    "chkOptional3": "",
    "btnApply": "",
    "tabRequired": "",
    "tabOptional": "",
    "tabOptional2": "",
    "tabOptional3": "",
    "requiredDesc": "",
    "optionalDesc": "",
    "optional2Desc": "",
    "optional3Desc": "",
    "providerColum": "",
    "nameColum": "",
    "purposeColum": "",
    "dataColum": "",
    "typeColum": ""
  }
}
export default l10n;
