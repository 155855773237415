const l10n: I18NCookieSetLayerTextData = {

        "confirmToast":{
        "content": "NCSOFT manages your personal information with safety.<br>We use cookies and other tracking technologies to enhance website performance and provide targeted ads. We aim to improve continuously by saving user-related information for the purpose of traffic analysis and visitor statistics.<br>By clicking “Agree to All”, you’re agreeing to save cookies on your device, and by clicking “Decline All”, only the strictly necessary cookies will be saved.<br>You can always find the details from <a href='#'>Cookie Policy</a> and change the agreement settings or withdraw.",
        "BtnApply": "Agree to All",
        "BtnDeny": "Decline All",
        "BtnApplyOptional": "Optional consent",
        "BtnSetting": "Settings"
    },
    "settingModal":{
        "msgSuccess": "Changes made to settings have been saved.",
        "titleLayer": "NCSOFT COOKIE SETTINGS",
        "topDesc": "When you visit any web site, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to. The information does not usually directly identify you, but it can give you a more personalized web experience.<br>Because we respect your right to privacy, you can choose not to allow some types of cookies. Click on the different category headings to find out more and change our default settings. However, blocking some types of cookies may impact your experience of the site and the services we are able to offer.<br>In some cases, data obtained from cookies is shared with third parties and may be considered a “sale of personal information” under the California Consumer Privacy Act(CCPA). You can exercise your right to opt-out of that sharing at any time by disabling cookies. To find out more please read our <a href='#'>cookie policy.</a>",
        "chkRequired": "Necessary cookies",
        "chkOptional": "Functional cookies",
        "chkOptional2": "Performance or Analytical Cookies",
        "chkOptional3": "Targeting / Advertising Cookies",
        "btnApply": "Save settings",
        "tabRequired": "Necessary cookies",
        "tabOptional": "Functional cookies",
        "tabOptional2": "Performance or Analytical Cookies",
        "tabOptional3": "Targeting / Advertising Cookies",
        "requiredDesc": "These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in, or filling in forms. You can set your browser to block or alert you to the use of these cookies, but please note that if you block necessary cookies, then some parts of the website may not function properly.",
        "optionalDesc": "These cookies enable the website to provide enhanced functionality and personalization (such as choice of language or mobile device preferences). They may be set by us or by third party providers whose services we have added to our pages. If you block these cookies then some or all of these services may not function properly.",
        "optional2Desc": "These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our website. They help us to know which pages are the most and least popular and see how visitors move around the website.  The information collected by these cookies is aggregated. If you do not allow these cookies we will not know when you have visited our website, and will not be able to monitor its performance.",
        "optional3Desc": "These cookies may be set through our website by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites. They store information that is based on uniquely identifying your browser and internet device. If you do not allow these cookies, you will experience less targeted advertising.",
        "providerColum": "Provider",
        "nameColum": "Cookie name",
        "purposeColum": "Purpose",
        "dataColum": "Lifespan",
        "typeColum": "Type"
  }
}
export default l10n;
