import { isPcPurpleUseragent } from "../../common/Utils";

export const GCookieSetLayerId = 'gCookieSettingLayer';
export const GCookieSetLayerClass = 'g-cookie-set-layer';

export const GCookieSetDimmedId = 'gCookieSettingDimmed';
export const GCookieSetDimmedClass = 'g-cookie-set-dimmed';
export const BtnClose = 'g-cookie-btn-close';
export const TopDesc = 'g-cookie-desc';
export const Chks = 'g-cookie-chk';
export const ChkRquired = 'g-cookie-chk-required';
export const ChkOptional = 'g-cookie-chk-optional';
export const ChkOptional2 = 'g-cookie-chk-optional-2';
export const ChkOptional3 = 'g-cookie-chk-optional-3';
export const ChkRquiredId = 'gCookieChkRequired';
export const ChkOptionalId = 'gCookieChkOptional';
export const ChkOptional2Id = 'gCookieChkOptional2';
export const ChkOptional3Id = 'gCookieChkOptional3';
export const RadioTabs = 'g-cookie-tab';
export const RadioTabRequired = 'g-cookie-tab-required';
export const RadioTabOptional = 'g-cookie-tab-optional';
export const RadioTabOptional2 = 'g-cookie-tab-optional-2';
export const RadioTabOptional3 = 'g-cookie-tab-optional-3';
export const RadioTabRequiredId = 'gCookieTabRequired';
export const RadioTabOptionalId = 'gCookieTabOptional';
export const RadioTabOptional2Id = 'gCookieTabOptional2';
export const RadioTabOptional3Id = 'gCookieTabOptional3';
export const RadioTabsName = 'gCookieTab';
export const Infos = 'g-cookie-info';
export const InfosContent = `${Infos}-content`;
export const InfosContentRow = `${InfosContent}-row`;
export const InfosContentCol = `${InfosContent}-col`;
export const InfoRequired = 'g-cookie-info-required';
export const InfoOptional = 'g-cookie-info-optional';
export const InfoOptional2 = 'g-cookie-info-optional-2';
export const InfoOptional3 = 'g-cookie-info-optional-3';
export const IsActive= 'is-active';
export const BtnApply = 'g-cookie-btn-apply';




// settingModal 탬플릿
export function getSeetingLayerTemplate(textData: SettingModalTextData, cookieDatas: CookieDataMap, zIndex?: number): string{

  return `
    <div id="${GCookieSetDimmedId}" class="${GCookieSetDimmedClass}" style="${zIndex ? `z-index:${zIndex}` : ''}"></div>
    <div id="${GCookieSetLayerId}" class="${GCookieSetLayerClass}-wrap ${isPcPurpleUseragent() ? 'purple-scroll-bar' : ''}" style="${zIndex ? `z-index:${zIndex+1}` : ''}">
      ${getTopTemplate(textData)}
      ${getMidTemplate(textData)}
      ${getBottomTemplaete(textData, cookieDatas)}
    </div>
  `;

}

function getTopTemplate(textData: SettingModalTextData){
  return `
  <div class="${GCookieSetLayerClass}-top">
    <div class="g-cookie-head">
      <div class="g-cookie-title">${textData.titleLayer}</div>
      <div class="${BtnClose}">
        <svg data-dark-mode="light" width="22" height="22" xmlns="http://www.w3.org/2000/svg">
          <path d="m17.707 3-7 7-6.999-7L3 3.707l7 7.001-7 6.999.708.707 6.999-7 7 7 .707-.707-7-6.999 7-7.001z" fill="#000" fill-rule="evenodd" fill-opacity=".68"/>
        </svg>
        <svg data-dark-mode="dark" width="22" height="22" xmlns="http://www.w3.org/2000/svg">
          <path d="m17.707 3-7 7-6.999-7L3 3.707l7 7.001-7 6.999.708.707 6.999-7 7 7 .707-.707-7-6.999 7-7.001z" fill="#FFF" fill-rule="evenodd" fill-opacity=".6"/>
        </svg>

      </div>
    </div>
    <div class="${TopDesc}">
      ${textData.topDesc}
    </div>
  </div>
  `;
}

function getMidTemplate(textData: SettingModalTextData){
  return `
  <div class="${GCookieSetLayerClass}-mid">
    <ul class="${Chks}-wrap">
      <li>
        <input class="${Chks} ${ChkRquired}" type="checkbox" name="gCookieChk" id="${ChkRquiredId}" checked disabled>
        <label class="${Chks}-label ${ChkRquired}-label" for="${ChkRquiredId}">
          <span class="label-custom-box"></span>
          <span class="label-text">${textData.chkRequired}</span>
        </label>
      </li>
      <li>
        <input class="${Chks} ${ChkOptional}" type="checkbox" name="gCookieChk" id="${ChkOptionalId}">
        <label class="${Chks}-label ${ChkOptional}-label" for="${ChkOptionalId}">
          <span class="label-custom-box"></span>
          <span class="label-text">   ${textData.chkOptional} </span>
        </label>
      </li>
      <li>
        <input class="${Chks} ${ChkOptional2}" type="checkbox" name="gCookieChk" id="${ChkOptional2Id}">
        <label class="${Chks}-label ${ChkOptional2}-label" for="${ChkOptional2Id}">
          <span class="label-custom-box"></span>
          <span class="label-text">   ${textData.chkOptional2} </span>
        </label>
      </li>
      <li>
        <input class="${Chks} ${ChkOptional3}" type="checkbox" name="gCookieChk" id="${ChkOptional3Id}">
        <label class="${Chks}-label ${ChkOptional3}-label" for="${ChkOptional3Id}">
          <span class="label-custom-box"></span>
          <span class="label-text">   ${textData.chkOptional3} </span>
        </label>
      </li>
    </ul>
    <div class="${BtnApply}-wrap">
      <div class="${BtnApply}">${textData.btnApply}</div>
    </div>
  </div>
  `;
}

function getBottomTemplaete(textData: SettingModalTextData, cookieDatas: CookieDataMap){
  return `
  <div class="${GCookieSetLayerClass}-bottom">
    <ul class="${RadioTabs}-wrap">
      <li>
        <input class="${RadioTabs} ${RadioTabRequired}" type="radio" name="${RadioTabsName}" id="${RadioTabRequiredId}" data-target="${InfoRequired}" >
        <label class="${RadioTabs}-label ${RadioTabRequired}-label" for="${RadioTabRequiredId}">${textData.tabRequired}</label>
      </li>
      <li>
        <input class="${RadioTabs} ${RadioTabOptional}" type="radio" name="${RadioTabsName}" id="${RadioTabOptionalId}" data-target="${InfoOptional}">
        <label class="${RadioTabs}-label ${RadioTabOptional}-label" for="${RadioTabOptionalId}">${textData.tabOptional}</label>
      </li>
      <li>
        <input class="${RadioTabs} ${RadioTabOptional2}" type="radio" name="${RadioTabsName}" id="${RadioTabOptional2Id}" data-target="${InfoOptional2}">
        <label class="${RadioTabs}-label ${RadioTabOptional2}-label" for="${RadioTabOptional2Id}">${textData.tabOptional2}</label>
      </li>
      <li>
        <input class="${RadioTabs} ${RadioTabOptional3}" type="radio" name="${RadioTabsName}" id="${RadioTabOptional3Id}" data-target="${InfoOptional3}">
        <label class="${RadioTabs}-label ${RadioTabOptional3}-label" for="${RadioTabOptional3Id}">${textData.tabOptional3}</label>
      </li>
    </ul>
    <div class="${Infos}-wrap">
      <div class="${Infos} ${InfoRequired}">
        <div class="${Infos}-title">
          ${textData.requiredDesc}
        </div>
        <div class="${InfosContent}-wrap">
          <ul class="${InfosContent}">
            <li class="${InfosContentRow} ${InfosContentRow}-head">
              <div class="${InfosContentCol}"><p>${textData.providerColum}</p></div>
              <div class="${InfosContentCol}"><p>${textData.nameColum}</p></div>
              <div class="${InfosContentCol}"><p>${textData.purposeColum}</p></div>
              <div class="${InfosContentCol}"><p>${textData.dataColum}</p></div>
              <div class="${InfosContentCol}"><p>${textData.typeColum}</p></div>
            </li>
            ${getCookieTemplate(cookieDatas.required)}
          </ul>
        </div>
      </div>
      <div class="${Infos} ${InfoOptional}">
        <div class="${Infos}-title">
          ${textData.optionalDesc}
        </div>
        <div class="${InfosContent}-wrap">
          <ul class="${InfosContent}">
            <li class="${InfosContentRow} ${InfosContentRow}-head">
              <div class="${InfosContentCol}"><p>${textData.providerColum}</p></div>
              <div class="${InfosContentCol}"><p>${textData.nameColum}</p></div>
              <div class="${InfosContentCol}"><p>${textData.purposeColum}</p></div>
              <div class="${InfosContentCol}"><p>${textData.dataColum}</p></div>
              <div class="${InfosContentCol}"><p>${textData.typeColum}</p></div>
            </li>
            ${getCookieTemplate(cookieDatas.optional)}
          </ul>
        </div>
      </div>
      <div class="${Infos} ${InfoOptional2}">
        <div class="${Infos}-title">
          ${textData.optional2Desc}
        </div>
        <div class="${InfosContent}-wrap">
          <ul class="${InfosContent}">
            <li class="${InfosContentRow} ${InfosContentRow}-head">
              <div class="${InfosContentCol}"><p>${textData.providerColum}</p></div>
              <div class="${InfosContentCol}"><p>${textData.nameColum}</p></div>
              <div class="${InfosContentCol}"><p>${textData.purposeColum}</p></div>
              <div class="${InfosContentCol}"><p>${textData.dataColum}</p></div>
              <div class="${InfosContentCol}"><p>${textData.typeColum}</p></div>
            </li>
            ${getCookieTemplate(cookieDatas.optional2)}
          </ul>
        </div>
      </div>
      <div class="${Infos} ${InfoOptional3}">
      <div class="${Infos}-title">
        ${textData.optional3Desc}
      </div>
      <div class="${InfosContent}-wrap">
        <ul class="${InfosContent}">
          <li class="${InfosContentRow} ${InfosContentRow}-head">
            <div class="${InfosContentCol}"><p>${textData.providerColum}</p></div>
            <div class="${InfosContentCol}"><p>${textData.nameColum}</p></div>
            <div class="${InfosContentCol}"><p>${textData.purposeColum}</p></div>
            <div class="${InfosContentCol}"><p>${textData.dataColum}</p></div>
            <div class="${InfosContentCol}"><p>${textData.typeColum}</p></div>
          </li>
          ${getCookieTemplate(cookieDatas.optional3)}
        </ul>
      </div>
    </div>
    </div>
  </div>
  `;
}

function getCookieTemplate(cookieDatas: CookieData[]): string{
  let result = '';
  // for(let a = 0; a < 50; a++){
    cookieDatas.forEach(cookieData => {
      if(!cookieData.name) return ;
      result += `
        <li class="${InfosContentRow} ${InfosContentRow}-body">
          <div class="${InfosContentCol}"><p>${cookieData.provider}</p></div>
          <div class="${InfosContentCol}"><p>${cookieData.name.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&apos;')}</p></div>
          <div class="${InfosContentCol}"><p>${cookieData.purpose}</p></div>
          <div class="${InfosContentCol}"><p>${cookieData.date}</p></div>
          <div class="${InfosContentCol}"><p>${cookieData.type}</p></div>
        </li>
      `;
    });
  // }


  return result;
}
