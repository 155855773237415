const l10n: I18NCookieSetLayerTextData = {

        "confirmToast":{
        "content": "NCSOFT gestiona tus datos personales con seguridad.<br>Utilizamos cookies y otras tecnologías de seguimiento para mejorar el rendimiento del sitio web y ofrecer anuncios específicos. Nuestro objetivo es mejorar continuamente guardando datos relacionados con el usuario para analizar el tráfico y obtener estadísticas de visitas.<br>Al hacer clic en «Aceptar todo», permites que se guarden cookies en tu dispositivo, y al hacer clic en «Rechazar todo», solo se guardarán las cookies estrictamente necesarias.<br>Puedes encontrar los detalles del acuerdo en <a href='#'>Política de cookies</a> y cambiar la configuración o retirar tu consentimiento.",
        "BtnApply": "Aceptar todo",
        "BtnDeny": "Rechazar todo",
        "BtnApplyOptional": "Consentimiento opcional",
        "BtnSetting": "Ajustes"
    },
    "settingModal":{
      "msgSuccess": "",
      "titleLayer": "",
      "topDesc": "",
      "chkRequired": "",
      "chkOptional": "",
      "chkOptional2": "",
      "chkOptional3": "",
      "btnApply": "",
      "tabRequired": "",
      "tabOptional": "",
      "tabOptional2": "",
      "tabOptional3": "",
      "requiredDesc": "",
      "optionalDesc": "",
      "optional2Desc": "",
      "optional3Desc": "",
      "providerColum": "",
      "nameColum": "",
      "purposeColum": "",
      "dataColum": "",
      "typeColum": ""
    }
}
export default l10n;
