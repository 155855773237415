import {version} from '../../../package.json';
import { namespace } from '../common/Utils';
import {ConfirmToastIndex, SettingLayerIndex} from './index';

namespace('ncui.cookieLayer');
if (!window.ncui.ver) window.ncui.ver = version;

window.ncui.cookieLayer.confirmToast = ConfirmToastIndex;
window.ncui.cookieLayer.settingLayer = SettingLayerIndex;

