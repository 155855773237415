// import $ from 'jquery';
import { getCookie, setCookie } from '../../common/Utils';
import { callGetCookieData, callGetI18NMessage } from '../service';
import { getConfirmToastTemplate, BtnApply, BtnDeny, BtnConfig, GCookieConfirmToastId, GCookieConfirmDimmedId, IsActive, BtnApplyOptional, ContentWrap } from '../template/confirmToast';
import { getCountry, getLang, getLangForL10n, getPlayncDomain, isLandscapeMode, isSDKApp } from '../util/util';
import AbsGlobalCookie from './AbsGlobalCookie';

const SHOW_TOAST = 'SHOW_TOAST';
const NO_SHOW_TOAST = 'NO_SHOW_TOAST';
const CONDITIONAL_OF_APPLYCOOKIE_SHOW_TOAST = 'CONDITIONAL_SHOW_TOAST';
export default class ConfirmToast extends AbsGlobalCookie {
  _isPreGDPRCountry: boolean | null = null;

  constructor({ zIndex = 1, apiDomain = '', contiCookieDataApi = '', }: { zIndex?: number, apiDomain?: string, contiCookieDataApi?: string, } = {}) {
    super({ zIndex, apiDomain, contiCookieDataApi, });

    this.init();
  }

  async init() {
    try {

      // 허용된 언어만 선택
      let lang = getLang({
        onlyUsingLanguageArr: [ 'ar', 'en', 'id', 'ja', 'ko', 'th', 'zh', 'ru', 'de', 'es_ES', 'es_MX', 'fr', 'it', 'pt', 'tr', 'hi', 'pl', 'vi'],
        defaultLang: 'en',
      });
      let langForL10n = getLangForL10n({
        onlyUsingLanguageArr: [ 'ar', 'en', 'id', 'ja', 'ko', 'th', 'zh', 'ru', 'de', 'es_ES', 'es_MX', 'fr', 'it', 'pt', 'tr', 'hi', 'pl', 'vi'],
        defaultLang: 'en',
      });
      let country = getCountry();

      // 콘티에서 쿠키 데이터 가져오기 & l10n 세팅하기
      await Promise.all([this.getI18nMessage(langForL10n), this.getCookieData(), this.getGeoLocationCountry()]);

      // 현재 저장된 쿠키의 값 유효성 체크 - 값이 없는 경우는 유효성 체크 통과 true
      const {isValidateCookieGeoLocationInfo, isValidateCookieApplyCookie} = this.chkValidateCookie();
      !isValidateCookieGeoLocationInfo && this.deleteGeoLocationInfoAndReloadPage();
      !isValidateCookieApplyCookie && this.deleteApplyCookie();

      // 현재 GDPR 대상국가이면 동의하지 않은 쿠키 지우기
      if (this.isNowGDPRCountry) {
        this.deleteCookiesNotApplied();
      }

      /**
       * 토스트창 출력해도 되는지 확인
       *
       * 1) GDPR 대상국가 인지 확인 [CSFDT-2182]
       * 2) 퍼플 pc / 퍼플 모바일이 아닌지 [CSD-1217]
       *    변경 => 퍼플 모바일 일때만 출력 안함. [CSFDT-2241]
       * 3) 저장된 applyCookie 쿠키값을 통해 토스트창 출력해도 되는지 확인.
       */
      const isOpenToast = !this.isPurpleMoUseragent() && this.chkIsOpenToast(this.cookieVersionOptionals);

      console.log("this.isPurpleMoUseragent()", this.isPurpleMoUseragent());
      console.log("this.chkIsIsOpenToastByGDPRCountry()", this.chkIsIsOpenToastByGDPRCountry());
      console.log("this.chkIsIsOpenToastByApplyCookie(curOptCookieListVer", this.chkIsIsOpenToastByApplyCookie(this.cookieVersionOptionals));

      // 판벌이 끝났으면, GEO_LOCATION_INFO 쿠키 정보를 최신 isNowGDPRCountry 정보로 덮어 써준다
      // setCookie(this.GEO_LOCATION_INFO, String(JSON.stringify({isGDPRCountry: this.isNowGDPRCountry, geoLocation: this.geoLocationCountry})), 180, '.plaync.com');
      this.setCookieByApi(this.GEO_LOCATION_INFO, String(JSON.stringify({isGDPRCountry: this.isNowGDPRCountry, geoLocation: this.geoLocationCountry})), 180);

      // [CSFDT-2320] 동의모드 추가 - applyCookie 가 있는 경우 동의 모드 쿠키에 싱크 맞춤
      if(getCookie(this.APPLY_COOKIE)){
        const applyCookieTypeArr = this.getHasBeenAppliedCookieTypes();
        const consentMode = this.getConsentModeFromApplyCookie(applyCookieTypeArr);
        await this.setCookieByApi('consentMode',  JSON.stringify(consentMode), 180);
      }

      if(!isOpenToast){
        console.log('레이어를 출력하지 않습니다');

        console.log('isPrevDPRCountry: ', this._isPreGDPRCountry);
        console.log('isNowGDPRCountry: ', this.isNowGDPRCountry);
        console.log('isPurpleMoUseragent: ', this.isPurpleMoUseragent());
        console.log('isAppliedCookie: ', !!getCookie(this.APPLY_COOKIE));
        console.log('prev-cookieVer: ', window.decodeURIComponent(getCookie(this.APPLY_COOKIE)) && JSON.parse(window.decodeURIComponent(getCookie(this.APPLY_COOKIE)))?.optCookieListVer ,'cur-cookieVer: ', this.cookieVersionOptionals);

        return;
      }

      // 템플릿 세팅
      this.setTemplate(this.l10nText.confirmToast, this.zIndex);

      // 이벤트 바인딩
      this.bindEvent();

      // 토스트 레이어 오픈
      this.diplayToastAndDimmed(true);

      // window.showToast = this.diplayToastAndDimmed.bind(null, true);
    } catch (err) {
      console.error(err);
    }
  }

  setTemplate(textData: ConfirmToastTextData, zIndex?: number) {
    // 템플릿 만들기
    let template = getConfirmToastTemplate(textData, this.isRasied(), zIndex );

    // 템플릿 붙이기
    document.body.insertAdjacentHTML('beforeend', template);
  }

  bindEvent() {
    // 모두 동의하기 클릭
    document.addEventListener('click', (e) => {
      const delegateTarget = (e.target as HTMLElement)?.closest(`#${GCookieConfirmToastId} .${BtnApply}`);
      delegateTarget && this.onClickBtnApply();
    });

    // 모두 거부하기 클릭
    document.addEventListener('click', (e) => {
      const delegateTarget = (e.target as HTMLElement)?.closest(`#${GCookieConfirmToastId} .${BtnDeny}`);
      delegateTarget && this.onClickBtnDeny();
    });

    // 선택 통의하기 클릭
    document.addEventListener('click', (e) => {
      const delegateTarget = (e.target as HTMLElement)?.closest(`#${GCookieConfirmToastId} .${BtnApplyOptional}`);
      delegateTarget && this.onClickBtnApplyOptional();
    });
    // 설정 클릭
    document.addEventListener('click', (e) => {
      const delegateTarget = (e.target as HTMLElement)?.closest(`#${GCookieConfirmToastId} .${BtnConfig}`);
      delegateTarget && this.onClickBtnSetting();
    });
    // 쿠키정책(a 태크) 클릭시
    document.addEventListener('click', (e) => {
      const delegateTarget = (e.target as HTMLElement)?.closest(`#${GCookieConfirmToastId} .${ContentWrap} a`);
      delegateTarget && this.onClickContentWrapAnchorTag(e);
    });
    // 딤드 클릭
    document.addEventListener('click', (e) => {
      const delegateTarget = (e.target as HTMLElement)?.closest(`#${GCookieConfirmDimmedId}`);
      delegateTarget && this.onClickDimmed();
    });
  }

  // a 태그(쿠키정책) 클릭시
  onClickContentWrapAnchorTag = (e: MouseEvent) => {
    e.preventDefault();

    // 쿠키허용상태를 저장하는 쿠키 생성
    this.applyCookieOnlyRequired(this.URL_COOKIE_POLICY_LAYER_OPEN);
  };

  // 딤드 클릭
  onClickDimmed = () => {
    // 쿠키허용상태를 저장하는 쿠키 생성
    this.applyCookieOnlyRequired(location.href);

    // 레이어 닫기
    this.diplayToastAndDimmed(false);
  };

  // 선택 동의 클릭
  onClickBtnApplyOptional = () => {
    // 쿠키허용상태를 저장하는 쿠키 생성
    this.applyCookieOnlyRequired(this.URL_COOKIE_POLICY_LAYER_OPEN);
  };

  // 설정 클릭
  onClickBtnSetting = () => {
    // 쿠키허용상태를 저장하는 쿠키 생성
    this.applyCookieOnlyRequired(this.URL_COOKIE_POLICY_LAYER_OPEN);
  };

  // 모두 거부하기 클릭
  onClickBtnDeny = () => {
    // 쿠키허용상태를 저장하는 쿠키 생성
    this.applyCookieOnlyRequired(location.href);

    // 레이어 닫기
    this.diplayToastAndDimmed(false);
  };

  // 모두 동의 하기 클릭
  onClickBtnApply = () => {

    // 쿠키허용상태를 저장하는 쿠키 생성
    this.applyCookieAll(location.href);

    // 레이어 닫기
    this.diplayToastAndDimmed(false);
  };

  /**
  * 토스트 팝업 디스 플레이
  */
  diplayToastAndDimmed = (flag: boolean) => {
    this.displayDimmed(flag);
    this.displayToast(flag);
  };

  displayDimmed(flag: boolean) {
    if (flag) {
      document.getElementById(GCookieConfirmDimmedId)?.classList.add(IsActive);
    } else {
      document.getElementById(GCookieConfirmDimmedId)?.classList.remove(IsActive);
    }
  }

  displayToast(flag: boolean) {
    if (flag) {
      document.getElementById(GCookieConfirmToastId)?.classList.add(IsActive);
    } else {
      document.getElementById(GCookieConfirmDimmedId)?.classList.remove(IsActive);
    }
  }

  // 동의 토스트 레이어 출력해도 되는지 확인
  chkIsOpenToast(curOptCookieListVer:string){
    const resultChkIsIsOpenToastByGDPRCountry = this.chkIsIsOpenToastByGDPRCountry();

    if(resultChkIsIsOpenToastByGDPRCountry === SHOW_TOAST){
      return true;
    }else if (resultChkIsIsOpenToastByGDPRCountry === NO_SHOW_TOAST){
      return false;
    }else {
      return this.chkIsIsOpenToastByApplyCookie(curOptCookieListVer)
    }
  }

  // 이전에 저장된 GEO_LOCATION_INFO 쿠키값과, 현재의 isNowGDPRCountry 갑을 비교하여 레이어 출력여부 결정
  chkIsIsOpenToastByGDPRCountry(){
    // 판별 재로
    let isPreGDPRCountry: boolean;
    let isNowGDPRCountry: boolean;

    // 쿠키문자열
    let preGeoLocationInfoStr = getCookie(this.GEO_LOCATION_INFO);

    /**
     * 이전에 저장된 값이 없을때
     *
     * ** 최우선적으로 ** 이전에 저장된 쿠키가 있어는지 여부에 따라 판별을 먼저 해야 한다.
     * 아후에 진행될 판별을 위헤 이전에 저장된 쿠키값을 뽑아서 사용 하기 위해, JSON.parse 를 사용해야 하는데
     * 이전에 저장된 쿠키가 없는겨우 JSON.parse에서 애러가 나기 때문이다.
     * 그리고 pasing 이 안되는 형태의 쿠키가 저장된 경우, catch하고 싶단 말이지 (이상한 형태의 쿠키는 지워버리고, 페이지를 리로드 하고 싶다.)
     */
    if(!preGeoLocationInfoStr){
      if(!this.isNowGDPRCountry){
        return NO_SHOW_TOAST;
      }else{
        // 이전에 저장된 값이 없는데, 현재 값이 isGDPRCountry 인경우 ** 이것때문에 이 판별이 필요했던거임(1/2) **
        return SHOW_TOAST;
      }
    }

    /**
     * 이전에 저장된 값이 있을때
     */
    // 쿠키 문자열값을 obj로
    let preGeoLocationInfoObj = {} as GDPRCookieStateCookie;
    preGeoLocationInfoObj = JSON.parse(window.decodeURIComponent(preGeoLocationInfoStr));

    // 판별 재로 준비 완료
    isPreGDPRCountry = preGeoLocationInfoObj.isGDPRCountry;
    isNowGDPRCountry = this.isNowGDPRCountry;

    this._isPreGDPRCountry = isPreGDPRCountry; // 그냥 콘솔log용

    // 현재의 GDPR 체크가 false 인경우
    if(!isNowGDPRCountry){
      return NO_SHOW_TOAST;

    // 현재의 GDPR 체크가 true 인경우
    }else{
      // 현재의 GDPR 체크가 true && 이전의 GDPR 체크가 fasle 인경우 ** 이것때문에 이 판별이 필요했던거임(2/2) **
      if(!isPreGDPRCountry){
        return SHOW_TOAST;
      // 현재의 GDPR 체크가 true && 이전의 GDPR 체크가 true 인경우, applyCookie 확인해봐야함
      }else{
        return CONDITIONAL_OF_APPLYCOOKIE_SHOW_TOAST;
      }
    }
  }

  // 이전에 저장된 applyCookie 의 내용으로 레이어 출력 여부를 결정
  chkIsIsOpenToastByApplyCookie(curOptCookieListVer: string){

    // 기존에 유저가 선택하여 저장된, 유저가 동의한 종류의 쿠키
    let preApplyCookieStr = getCookie(this.APPLY_COOKIE);

    // 쿠키허용상태를 저장한 쿠키가 없는경우 & 6개월 초과된 경우(6개월 초과되면 자동으로 쿠키허용상태를 저장한 쿠키가 삭제e됨)
    if (!preApplyCookieStr) return true;

    let preApplyCookieObj = {} as ApplyCookieStateCookie;
    preApplyCookieObj = JSON.parse(window.decodeURIComponent(preApplyCookieStr)); // setCookie 유틸에서 자동으로 encodeURIComponent 를 실행한다.

    // 기존에 전부 허용이 아니면서 && 쿠키리스트의 갱신이 있었던 경우
    if (!this.isAllCookieApply(preApplyCookieObj.typeArr) && preApplyCookieObj.optCookieListVer !== curOptCookieListVer) {
      return true;
    }

    // 나머지는 전부 false
    return false;
  }


  /**
   * 동의 레이어를 바닦에서 조금 띄울지 결정
   * [PSD-12190] [CSDT-2346]
  */
  isRasied(){
    return isSDKApp() && !isLandscapeMode()
  }

}

