const l10n: I18NCookieSetLayerTextData = {

        "confirmToast":{
        "content": "NCSOFT gère vos informations personnelles en toute sécurité.<br>Nous utilisons des cookies et d'autres technologies de tracking pour améliorer les performances de notre site Web et proposer des publicités ciblées. Nous cherchons à nous améliorer en permanence en enregistrant des informations relatives aux utilisateurs dans le but d'analyser la fréquentation de notre site et d'accéder à des statistiques sur ses visiteurs.<br>En cliquant sur « Tout accepter », vous acceptez d'enregistrer des cookies sur votre appareil. En cliquant sur « Tout refuser », seuls les cookies nécessaires seront enregistrés.<br>Vous pouvez toujours consulter les détails de notre <a href='#'>Politique en matière de cookies</a> et modifier les paramètres de consentement ou retirer votre consentement.",
        "BtnApply": "Accepter tout",
        "BtnDeny": "Tout refuser",
        "BtnApplyOptional": "Consentement facultatif",
        "BtnSetting": "Paramètres"
    },
    "settingModal":{
      "msgSuccess": "",
      "titleLayer": "",
      "topDesc": "",
      "chkRequired": "",
      "chkOptional": "",
      "chkOptional2": "",
      "chkOptional3": "",
      "btnApply": "",
      "tabRequired": "",
      "tabOptional": "",
      "tabOptional2": "",
      "tabOptional3": "",
      "requiredDesc": "",
      "optionalDesc": "",
      "optional2Desc": "",
      "optional3Desc": "",
      "providerColum": "",
      "nameColum": "",
      "purposeColum": "",
      "dataColum": "",
      "typeColum": ""
    }
}
export default l10n;
