
export const namespace = (_namespace = 'window.console', _parent = window) => {
  if (typeof _namespace === 'string' && _namespace) {
      let parts = _namespace.split('.');
      for (let i = 0, max = parts.length; i < max; i++) {
          if (!_parent[parts[i]]) _parent[parts[i]] = {};
          _parent = _parent[parts[i]];
      }
  }
  return _parent;
};



export const getUriParam = (uri, parameterName) => {
  if (uri.length < 1) return '';
  uri = uri.split('#')[0];

  let tmpArr = uri.split('?');
  if (tmpArr.length < 2) return '';

  let paramStr = tmpArr[1],
    params = paramStr.split('&');

  for (let param = '', eqIndex = -1, keyStr = '', valueStr = '', i = 0, max = params.length; i < max; ++i) {
    param = params[i];

    eqIndex = param.indexOf('=');
    if (eqIndex >= 0) {
      keyStr = param.substr(0, eqIndex);
      valueStr = param.substr(eqIndex + 1);

      if (keyStr === parameterName) return valueStr;
    }
  }

  return '';
};


/**
 * 쿠키 세팅
 * @param  {String} name 쿠키이름
 * @param  {String} value 쿠키값
 * @param  {Number} expiredays 만료날짜
 * @example
 * setCookie('topBanner', 'show', 1);
 */
 export const setCookie = (name = '', value = '', expiredays = 1, domain) => {
  let todayDate = new Date();
  todayDate.setDate(todayDate.getDate() + expiredays);


  // expiredays가 0이면 session 쿠키가 만들어짐

  if(expiredays === 0){
      document.cookie = `${name}=${encodeURIComponent(value)}; path=/; SameSite=Lax;; domain=${domain ? domain : ''}`;
  }else if(expiredays < 0){
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;; path=/; SameSite=Lax;; domain=${domain ? domain : ''}`;

  }else{
      document.cookie = `${name}=${encodeURIComponent(value)}; path=/; expires=${todayDate.toGMTString()};SameSite=Lax;; domain=${domain ? domain : ''}`;
  }
};

/**
* 쿠키 확인
* @param  {String} name 쿠키이름
* @returns {String}
* @example
* getCookie('topBanner') == 'show' // true : 쿠키가 입력 되어 있는 상태
*/
export const getCookie = (name = '') => {
  let cookies = document.cookie;
  if (cookies.indexOf(name) == -1) return false;
  let cookie = cookies.substr(cookies.indexOf(name));
  cookie = cookie.split(';')[0];
  cookie = cookie.substr(cookie.indexOf('=') + 1);
  return cookie;
};


export const getCookies = function(){
  var pairs = document.cookie.split(';');
  var cookies = {};
  for (var i=0; i<pairs.length; i++){
    var pair = pairs[i].split('=');
    cookies[(pair[0]+'').trim()] = unescape(pair.slice(1).join('='));
  }
  return cookies;
};
