const l10n: I18NCookieSetLayerTextData = {

        "confirmToast":{
        "content": "NCSOFT behandelt Ihre personenbezogenen Daten mit Sorgfalt.<br>Wir verwenden Cookies und andere Nachverfolgungstechnologien, um die Leistung der Website zu verbessern und gezielte Werbung anzubieten. Wir bemühen uns um eine kontinuierliche Verbesserung, indem wir nutzerbezogene Informationen zum Zweck der Verkehrsanalyse und Besucherstatistiken speichern.<br>Wenn Sie auf „Allem zustimmen“ klicken, stimmen Sie der Speicherung von Cookies auf Ihrem Gerät zu. Wenn Sie auf „Alles ablehnen“ klicken, werden nur die unbedingt notwendigen Cookies gespeichert.<br>Einzelheiten finden Sie jederzeit in der <a href='#'>Cookie-Richtlinie</a>. Sie können die Einstellungen außerdem ändern oder die Zustimmung widerrufen.",
        "BtnApply": "Allem zustimmen",
        "BtnDeny": "Alles ablehnen",
        "BtnApplyOptional": "Optionale Einwilligung",
        "BtnSetting": "Einstellungen"
    },
    "settingModal":{
        "msgSuccess": "ChaDie Einstellungsänderungen wurden gespeichert.",
        "titleLayer": "NCSOFT COOKIE EINSTELLUNGEN",
        "topDesc": "Wenn Sie eine Website besuchen, kann diese Informationen in Ihrem Browser speichern oder abrufen, meist in Form von Cookies. Diese Informationen können sich auf Sie, Ihre Voreinstellungen oder Ihr Gerät beziehen und werden hauptsächlich verwendet, damit die Website erwartungsgemäß funktioniert. Durch diese Informationen können Sie in der Regel nicht direkt identifiziert werden, aber sie ermöglichen Ihnen ein personalisiertes Web-Erlebnis. Da wir Ihre Datenschutzrechte respektieren, können Sie festlegen, dass bestimmte Arten von Cookies nicht zugelassen werden. Klicken Sie auf die Überschriften der verschiedenen Kategorien, um mehr darüber zu erfahren und die Standardeinstellungen zu ändern. In einigen Fällen werden Daten, die mithilfe von Cookies erhoben werden, an Dritte weitergegeben und können nach dem kalifornischen Verbraucherschutzgesetz (California Consumer Privacy Act, CCPA) als \"Verkauf von personenbezogenen Daten\" betrachtet werden. Sie können jederzeit von Ihrem Recht Gebrauch machen, diese Weitergabe zu unterbinden, indem Sie Cookies deaktivieren. Weitere Informationen dazu finden Sie in unserer <a href='#'>Cookie-Richtlinie</a>.",
        "chkRequired": "Erforderliche Cookies",
        "chkOptional": "Funktionelle Cookies",
        "chkOptional2": "PerPerformance- oder analytische Cookies",
        "chkOptional3": "Cookies für Targeting oder Werbung",
        "btnApply": "Einstellungen speichern",
        "tabRequired": "Necessary cookies",
        "tabOptional": "Functional cookies",
        "tabOptional2": "Performance or Analytical Cookies",
        "tabOptional3": "Targeting / Advertising Cookies",
        "requiredDesc": "Diese Cookies sind erforderlich, damit die Website funktioniert und können in unseren Systemen nicht deaktiviert werden. Sie werden in der Regel nur als Reaktion auf Ihre Aktionen gesetzt, die einer Serviceanforderung gleichkommen, beispielsweise beim Einstellen Ihrer Datenschutzeinstellungen, beim Anmelden oder beim Ausfüllen von Formularen. Sie können Ihren Browser so einstellen, dass er die Verwendung dieser Cookies blockiert oder Sie darauf hinweist. Bitte beachten Sie jedoch, dass einige Teile der Website möglicherweise nicht richtig funktionieren, wenn Sie erforderliche Cookies blockieren.",
        "optionalDesc": "Diese Cookies ermöglichen erweiterte Funktionen und die Personalisierung der Website (z. B. die Auswahl der Sprache oder Einstellungen für Mobilgeräte). Sie können von uns oder von Drittanbietern gesetzt werden, deren Services wir zu unseren Seiten hinzugefügt haben. Wenn Sie diese Cookies blockieren, funktionieren möglicherweise einige oder alle solchen Services nicht richtig.",
        "optional2Desc": "Mithilfe dieser Cookies können wir Besuche und Quellen von Datenverkehr zählen, um die Performance unserer Website zu ermitteln und zu optimieren. So erfahren wir, welche Seiten besonders beliebt und welche weniger beliebt sind und wie die Besucher auf der Website navigieren. Die von diesen Cookies erfassten Daten werden aggregiert. Wenn Sie diese Cookies nicht zulassen, wissen wir nicht, wann Sie unsere Website besucht haben, und können die Performance der Website nicht überwachen.",
        "optional3Desc": "Diese Cookies können über unsere Website von unseren Werbepartnern gesetzt werden. Diese Unternehmen verwenden möglicherweise Cookies, um ein Profil Ihrer Interessen zu erstellen und Ihnen relevante Werbung auf anderen Websites zu zeigen. Sie speichern basierend auf der eindeutigen Identifizierung Ihres Browsers und Internetgeräts Informationen. Wenn Sie diese Cookies nicht zulassen, wird Ihnen weniger gezielte Werbung angezeigt.",
        "providerColum": "Provider",
        "nameColum": "Cookie name",
        "purposeColum": "Purpose",
        "dataColum": "Lifespan",
        "typeColum": "Type"
  }
}
export default l10n;
