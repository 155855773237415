const l10n: I18NCookieSetLayerTextData = {

	"confirmToast":{
    "content": "NCSOFT는 귀하의 개인정보를 안전하게 관리하고 있습니다.<br>당사는 쿠키 및 기타 추적 기술을 사용하여 웹사이트 성능 향상과 함께 선호도에 맞는 광고를 전송하며, 트래픽 분석 및 방문자 통계 목적으로 귀하와 관련된 정보를 저장하여 서비스를 지속적으로 개선하고자 합니다.<br>\"모두 동의\"를 클릭하면 귀하의 기기에 쿠키를 저장하는 데 동의하는 것이며, \"모두 거부\"를 클릭하면 필수 쿠키 외 모두 저장되지 않습니다.<br>언제든지 <a href=\"#\">쿠키정책</a>을 통해 세부내용 확인 및 동의여부를 변경하거나 철회 할 수 있습니다.",
    "BtnApply": "모두 동의",
    "BtnDeny": "모두 거부",
		"BtnApplyOptional": "선택 동의",
    "BtnSetting": "설정"
  },
  "settingModal":{
    "msgSuccess": "",
    "titleLayer": "",
    "topDesc": "",
    "chkRequired": "",
    "chkOptional": "",
    "chkOptional2": "",
    "chkOptional3": "",
    "btnApply": "",
    "tabRequired": "",
    "tabOptional": "",
    "tabOptional2": "",
    "tabOptional3": "",
    "requiredDesc": "",
    "optionalDesc": "",
    "optional2Desc": "",
    "optional3Desc": "",
    "providerColum": "",
    "nameColum": "",
    "purposeColum": "",
    "dataColum": "",
    "typeColum": ""
  }
}
export default l10n;
