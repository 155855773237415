import axios from 'axios';
import { getLang, getWstaticUrlObjectStorage, getWstaticUrl, getContiApiLocale, getPlayncContiDomain, getPlayncDomain } from '../util/util';
import l10n from '../l10n';

export const dataLayerPushUpdateConsentMode = (consentMode: ConsentModeValue): Promise<boolean> => {
  return new Promise((resolve) => {
    if (!window.dataLayer) {
      console.error('dataLayer is not available.');
      resolve(true);  // dataLayer가 없으면 false를 resolve
    }
    window.dataLayer.push({
      'event': 'ga_consent',
      'event_callback': () => resolve(true),
      'event_timeout': 4000,
      ...consentMode
    });
  });
}

// 서버에서 쿠키 동의 내용 저장 하기
export async function cookieLog(data: unknown): Promise<I18NCookieSetLayerTextData | false> {
    try{
        let result = await axios({
            method: 'post',
            headers: {
                'content-type': 'application/json',
            },
            responseType: 'json',
            url: `${getPlayncDomain()}/cookie/log`,
            data
        });

        return result.data;

    }catch(err){
        // console.error('cookieLog fail');
        // return testData;
        // throw(err);
        return false;
    }
}

// i18n 텍스트 가져오기
export async function callGetI18NMessage(lang: Lang ): Promise<I18NCookieSetLayerTextData>{
    let wstaticUrl = getWstaticUrlObjectStorage();

    try{
        // let result = await axios({
        //     method: 'get',
        //     responseType: 'json',
        //     url: `${wstaticUrl}/i18n/global-cookie/${lang}.json`
        // });

        return l10n[lang];

    }catch(err){
        // console.error(`Get Json: ${lang} fail`);
        // return testData;
        throw(err);
    }
}

// Conti 에서 쿠키정보 가져오기
export async function callGetCookieData(contiApiUrl: string): Promise<ContiGroupAPIResponse>{
    // let _country = country.toLowerCase();
    let _service = document.documentElement.dataset.service;

    // https://www.plaync.com/global/index
    // https://lineage2m.plaync.com/naeu/index?redirect=false
    // if(_country === 'us' || _country === 'nc'){
        // _country = 'global'
    // }else if(_country === 'mo'){
    // https://tl.plaync.com/mo/index?redirect=false
        // _country = 'my'
    // }

    // let path = `/${_country}/conti/getContentGroup?service=plaync&groupAlias=global-cookie-list-group&subAlias=en-US`;
    // let path = `${getPlayncContiDomain()}/${_country}/conti/getContentGroup?service=plaync&groupAlias=global-cookie-list-group&subAlias=en-US`;
    // let path = contiApiUrl || `${getPlayncContiDomain()}/${_country}/conti/getContentGroup?service=plaync&groupAlias=global-cookie-list-group&subAlias=en-US`;
    let path = contiApiUrl || `${getPlayncContiDomain()}/conti/getContentGroup?service=plaync&groupAlias=global-cookie-list-group&subAlias=en-US`;

    try{
        let result = await axios({
            method: 'get',
            responseType: 'json',
            url: path,
            withCredentials: true,
        });

        return result.data;

    }catch(err){
        // console.error('Get Conti Data fail');
        // return tempCookieDatas;
        throw(err);
    }
}

// geoLocation 정보 가져오기
export async function callGetGeoLocationCountry(): Promise<{location: string}>{
    let path = `${getPlayncContiDomain()}/user/location`;

    try{
        let result = await axios({
            method: 'get',
            responseType: 'json',
            url: path,
            withCredentials: true,
        });

        return result.data;

    }catch(err){
        // console.error('Get Conti Data fail');
        // return tempCookieDatas;
        throw(err);
    }
}

export async function callSetApplyCookie(path: string) {
  try{
    // await Promise.allSettled(paths.map((path) => {
    await axios({
      method: 'get',
      responseType: 'json',
      url: path,
      withCredentials: true,
      timeout: 3000,
    })
    // }));

    return true;
  }catch(err){
    // console.log('!!')
    throw(err);
  }



  // try{
  //     let result = await axios({
  //         method: 'get',
  //         responseType: 'json',
  //         url: path,
  //         withCredentials: true,
  //     });

  //     return result.data;

  // }catch(err){
  //     // console.error('Get Conti Data fail');
  //     // return tempCookieDatas;
  //     throw(err);
  // }
}


// let tempCookieDatas: ContiAPIResponse = {
//   'service': 'plaync',
//   'alias': 'global-cookie-list',
//   'acceptLanguage': 'en-US',
//   'contentId': 4740,
//   'version': 2,
//   'compiledResource': '',
//   'domAttribute': {
//     'html': '',
//     'script': '',
//     'css': '',
//     'scss': '',
//     'jsonData': '{  "required": [    {      "name": "필수쿠키1 - 이름",      "purpose": "필수쿠키1 - 목적",      "date": "2달",      "type": "필수쿠키1 - 유형"    },    {      "name": "필수쿠키2 - 이름",      "purpose": "필수쿠키2 - 목적",      "date": "1달",      "type": "필수쿠키2 - 유형"    },    {      "name": "필수쿠키3 - 이름",      "purpose": "필수쿠키3 - 목적",      "date": "2일",      "type": "필수쿠키3 - 유형"    },    {      "name": "필수쿠키4 - 이름",      "purpose": "필수쿠키4 - 목적",      "date": "세션",      "type": "필수쿠키4 - 유형"    }  ],  "optional": [    {      "name": "선택쿠키1 - 이름",      "purpose": "선택쿠키1 - 이름",      "date": "2달",      "type": "선택쿠키1 - 이름"    },    {      "name": "선택쿠키2 - 이름",      "purpose": "선택쿠키2 - 이름",      "date": "6달",      "type": "선택쿠키2- 이름"    },    {      "name": "",      "purpose": "",      "date": "",      "type": ""    }  ]}'
//   },
//   'enabled': true,
//   'groupAlias': '',
//   'subAlias': '',
//   'script': '',
//   'html': '',
//   'css': '',
//   'scss': '',
//   'jsonData': '{  "required": [    {      "name": "필수쿠키1 - 이름",      "purpose": "필수쿠키1 - 목적",      "date": "2달",      "type": "필수쿠키1 - 유형"    },    {      "name": "필수쿠키2 - 이름",      "purpose": "필수쿠키2 - 목적",      "date": "1달",      "type": "필수쿠키2 - 유형"    },    {      "name": "필수쿠키3 - 이름",      "purpose": "필수쿠키3 - 목적",      "date": "2일",      "type": "필수쿠키3 - 유형"    },    {      "name": "필수쿠키4 - 이름",      "purpose": "필수쿠키4 - 목적",      "date": "세션",      "type": "필수쿠키4 - 유형"    }  ],  "optional": [    {      "name": "선택쿠키1 - 이름",      "purpose": "선택쿠키1 - 이름",      "date": "2달",      "type": "선택쿠키1 - 이름"    },    {      "name": "선택쿠키2 - 이름",      "purpose": "선택쿠키2 - 이름",      "date": "6달",      "type": "선택쿠키2- 이름"    },    {      "name": "",      "purpose": "",      "date": "",      "type": ""    }  ]}'
// };

let testData: I18NCookieSetLayerTextData = {
    confirmToast:{
        content: 'PLAYNC 사이트는 보다 향상된 사이트 이용을 위해 쿠키를 사용합니다. 본 레이어를 닫거나 동의 버튼을 클릭하면 귀하의 동의를 나타냅니다. 쿠키에 대한 자세한 안내는  <a href="#">쿠키정책</a> 에서 확인 가능합니다.',
        BtnApply: '모두 동의',
        BtnApplyOptional: '선택 동의',
        BtnDeny: '모두 거부',
        BtnSetting: '설정'
    },
    settingModal:{
        msgSuccess:'저장하였습ㄴ디ㅏ.',
        titleLayer: 'PLAYNC 쿠키 설정',
        topDesc: 'NCSOFT ( "당사")는 쿠키를 사용하여 웹 사이트를 운영하고 개인화 된 콘텐츠를 표시하고 비즈니스로서의 목표를 관리합니다. 아래에서 당사가 쿠키를 사용하는 방법에 대해 자세히 알아볼 수 있습니다. 모든 쿠키를 허용하거나 개별적으로 선택하거나 모두 거부 할 수 있습니다. 언제든지 <a href="#">쿠키정책</a>에서 더 많은 정보를 확인할 수 있습니다 .',
        chkRequired: '필수쿠키 허용',
        chkOptional: '선택쿠키 허용',
        chkOptional2: '선택쿠키2 허용',
        chkOptional3: '선택쿠키2 허용',
        btnApply: '저장하기',
        tabRequired: '필수쿠키',
        tabOptional: '선택쿠키',
        tabOptional2: '선택쿠키2',
        tabOptional3: '선택쿠키2',
        requiredDesc: '필수 쿠키는 보안, 네트워크 관리 및 접근성과 같은 핵심 기능을 활성화합니다. 브라우저 설정을 변경하여 이러한 기능을 비활성화 할 수 있지만 이는 웹 사이트 작동 방식에 영향을 미칠 수 있습니다.',
        optionalDesc: '선택 쿠키는 보안, 네트워크 관리 및 접근성과 같은 핵심 기능을 활성화합니다. 브라우저 설정을 변경하여 이러한 기능을 비활성화 할 수 있지만 이는 웹 사이트 작동 방식에 영향을 미칠 수 있습니다.',
        optional2Desc: '선택 쿠키2는 보안, 네트워크 관리 및 접근성과 같은 핵심 기능을 활성화합니다. 브라우저 설정을 변경하여 이러한 기능을 비활성화 할 수 있지만 이는 웹 사이트 작동 방식에 영향을 미칠 수 있습니다.',
        optional3Desc: '선택 쿠키2는 보안, 네트워크 관리 및 접근성과 같은 핵심 기능을 활성화합니다. 브라우저 설정을 변경하여 이러한 기능을 비활성화 할 수 있지만 이는 웹 사이트 작동 방식에 영향을 미칠 수 있습니다.',
        providerColum: '이름',
        nameColum: '이름',
        purposeColum: '목적',
        dataColum: '기간',
        typeColum: '유형',
    }
};

