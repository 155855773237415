const l10n: I18NCookieSetLayerTextData = {
  confirmToast: {
    content: "NCSOFT zarządza bezpiecznie Twoimi danymi osobowymi.<br>Nasza firma wykorzystuje pliki cookies i inne technologie śledzące celem poprawy efektywności oraz spersonalizowania reklam. Twoje dane są przechowywane w celu analizy ruchu oraz statystyk wizyt, które pomagają w nieustannym ulepszaniu serwisu.<br>Klikając \"wyrażam zgodę\" zgadzasz się na przechowywanie plików cookies na Twoim urządzeniu, wybranie opcji \"nie wyrażam zgody\" sprawi, że tylko niezbędne pliki cookies zostaną przechowywane.<br>Możesz w dowolnym momencie <a href=\"#\">sprawdzić lub zmienić swoje wybory</a>po zapoznaniu się z naszą polityką plików cookies.",
    BtnApply: 'Wyrażam zgodę',
    BtnDeny: 'Deie wyrażam zgody',
    BtnApplyOptional: '	Zgadzam się',
    BtnSetting: 'Ustawienia',
  },
  "settingModal":{
    "msgSuccess": "",
    "titleLayer": "",
    "topDesc": "",
    "chkRequired": "",
    "chkOptional": "",
    "chkOptional2": "",
    "chkOptional3": "",
    "btnApply": "",
    "tabRequired": "",
    "tabOptional": "",
    "tabOptional2": "",
    "tabOptional3": "",
    "requiredDesc": "",
    "optionalDesc": "",
    "optional2Desc": "",
    "optional3Desc": "",
    "providerColum": "",
    "nameColum": "",
    "purposeColum": "",
    "dataColum": "",
    "typeColum": ""
  }
};
export default l10n;
