const l10n: I18NCookieSetLayerTextData = {

        "confirmToast":{
        "content": "NCSOFT gestisce le tuo informazioni personali in sicurezza.<br>Utilizziamo cookie e altre tecnologie di tracciamento per migliorare la performance del sito web e fornire pubblicità mirate. Puntiamo a migliorarci sempre di più salvando le informazioni relative agli utenti per le analisi del traffico e le statistiche dei visitatori.<br>Cliccando 'Accetta tutto', acconsenti al salvataggio di cookie sul tuo dispositivo, e cliccando 'Rifiuta tutto', solo i cookie necessari verranno salvati.<br>Puoi sempre trovare i dettagli sulla <a href='#'>Informativa sui cookie</a> e cambiare le impostazioni di consenso o ritirarlo.",
        "BtnApply": "Accetta tutto",
        "BtnDeny": "Rifiuta tutto",
        "BtnApplyOptional": "Consenso facoltativo",
        "BtnSetting": "Impostazioni"
    },
    "settingModal":{
      "msgSuccess": "",
      "titleLayer": "",
      "topDesc": "",
      "chkRequired": "",
      "chkOptional": "",
      "chkOptional2": "",
      "chkOptional3": "",
      "btnApply": "",
      "tabRequired": "",
      "tabOptional": "",
      "tabOptional2": "",
      "tabOptional3": "",
      "requiredDesc": "",
      "optionalDesc": "",
      "optional2Desc": "",
      "optional3Desc": "",
      "providerColum": "",
      "nameColum": "",
      "purposeColum": "",
      "dataColum": "",
      "typeColum": ""
    }
}
export default l10n;
