const l10n: I18NCookieSetLayerTextData = {

        "confirmToast":{
        "content": "A NCSOFT gerencia seus dados pessoais com segurança.<br>Usamos cookies e outras tecnologias de rastreio para aprimorar o desempenho do site e fornecer anúncios personalizados. Queremos melhorar continuamente ao salvar os dados de usuário para fins de análise de tráfego e estatísticas de visitantes.<br>Ao clicar em 'Concordar com tudo', você aceita salvar cookies em seu dispositivo. Ao clicar em 'Recusar tudo', apenas os cookies obrigatórios serão salvos.<br>Você sempre pode ver os detalhes da <a href='#'>Política de Cookies</a> e mudar as configurações do contrato ou suspendê-lo.",
        "BtnApply": "Concordar com tudo",
        "BtnDeny": "Recusar tudo",
        "BtnApplyOptional": "Consentimento opcional",
        "BtnSetting": "Configurações"
    },
    "settingModal":{
      "msgSuccess": "",
      "titleLayer": "",
      "topDesc": "",
      "chkRequired": "",
      "chkOptional": "",
      "chkOptional2": "",
      "chkOptional3": "",
      "btnApply": "",
      "tabRequired": "",
      "tabOptional": "",
      "tabOptional2": "",
      "tabOptional3": "",
      "requiredDesc": "",
      "optionalDesc": "",
      "optional2Desc": "",
      "optional3Desc": "",
      "providerColum": "",
      "nameColum": "",
      "purposeColum": "",
      "dataColum": "",
      "typeColum": ""
    }
}
export default l10n;
