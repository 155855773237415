const l10n: I18NCookieSetLayerTextData = {

        "confirmToast":{
        "content": "NCSOFT administra tu información personal de manera segura.<br>Utilizamos cookies y otras tecnologías de seguimiento para mejorar el rendimiento del sitio web y brindar anuncios dirigidos. Nuestro objetivo es mejorar continuamente. Por ello, guardamos información relacionada con el usuario con fines de análisis de tráfico y estadísticas de visitantes.<br>Al hacer clic en “Aceptar todo”, aceptas que se guarden cookies en tu dispositivo y, al hacer clic en “Rechazar todo”, solo se guardarán las cookies estrictamente necesarias.<br>Siempre puedes encontrar información en la <a href='#'>Política de cookies</a> y cambiar la configuración del acuerdo o rescindirlo.",
        "BtnApply": "Aceptar todo",
        "BtnDeny": "Rechazar todo",
        "BtnApplyOptional": "Consentimiento opcional",
        "BtnSetting": "Configuración"
    },
    "settingModal":{
      "msgSuccess": "",
      "titleLayer": "",
      "topDesc": "",
      "chkRequired": "",
      "chkOptional": "",
      "chkOptional2": "",
      "chkOptional3": "",
      "btnApply": "",
      "tabRequired": "",
      "tabOptional": "",
      "tabOptional2": "",
      "tabOptional3": "",
      "requiredDesc": "",
      "optionalDesc": "",
      "optional2Desc": "",
      "optional3Desc": "",
      "providerColum": "",
      "nameColum": "",
      "purposeColum": "",
      "dataColum": "",
      "typeColum": ""
    }
}
export default l10n;
