const l10n: I18NCookieSetLayerTextData = {

	"confirmToast":{
    "content": "NCSOFT आपकी व्यक्तिगत जानकारी को सुरक्षा के साथ प्रबंधित करता है.<br>हम वेबसाइट के प्रदर्शन को बेहतर बनाने और लक्षित विज्ञापन प्रदान करने के लिए कुकीज़ और अन्य ट्रैकिंग तकनीकों का उपयोग करते हैं. हमारा लक्ष्य ट्रैफ़िक विश्लेषण और विज़िटर आंकड़ों के उद्देश्य से उपयोगकर्ता-संबंधी जानकारी को सेव कर लगातार सुधार करना है. <br>\"सभी से सहमत\" पर क्लिक करके, आप अपने डिवाइस पर कुकीज़ को सेव करने के लिए सहमति दे रहे हैं, और \"सभी को अस्वीकार करें\" पर क्लिक करके, केवल आवश्यक कुकीज़ ही सेव की जाएंगी. <br>आप हमेशा <a href='#'>कुकी नीति</a> से विवरण पा सकते हैं और अनुबंध सेटिंग्स बदल सकते हैं या सहमति वापस ले सकते हैं.",
    "BtnApply": "सभी से सहमत",
    "BtnDeny": "सभी को अस्वीकार करें",
		"BtnApplyOptional": "वैकल्पिक सहमति",
    "BtnSetting": "सेटिंग्स"
  },
  "settingModal":{
    "msgSuccess": "",
    "titleLayer": "",
    "topDesc": "",
    "chkRequired": "",
    "chkOptional": "",
    "chkOptional2": "",
    "chkOptional3": "",
    "btnApply": "",
    "tabRequired": "",
    "tabOptional": "",
    "tabOptional2": "",
    "tabOptional3": "",
    "requiredDesc": "",
    "optionalDesc": "",
    "optional2Desc": "",
    "optional3Desc": "",
    "providerColum": "",
    "nameColum": "",
    "purposeColum": "",
    "dataColum": "",
    "typeColum": ""
  }
}
export default l10n;
