

const l10n: I18NCookieSetLayerTextData = {
	"confirmToast":{
    "content":"تقوم NCSOFT بإدارة معلوماتك الشخصية بأمان.<br>نحن نستخدم ملفات تعريف الارتباط (Cookie) وتقنيات التتبع الأخرى لتحسين أداء موقع الويب، ولإرسال إعلانات على اهتماماتك، ولتحسين خدماتنا باستمرار عن طريق تخزين معلوماتك لأغراض تحليل حركة المرور وإحصاءات الزوار.<br>\"بالنقر على 'موافق على الكل' ، فإنك توافق على تخزين ملفات تعريف الارتباط على جهازك، وبالنقر فوق 'رفض الكل'، لا يتم تخزين ملفات تعريف الارتباط باستثناء ملفات تعريف الارتباط الإجبارية.<br>يمكنك التحقق من التفاصيل وتغيير الموافقة أو سحبها في أي وقت عن طريق <a href='#'>سياسة ملفات تعريف الارتباط</a>.",
    "BtnApply": "الموافقة على الكل",
    "BtnDeny": "رفض الكل",
    "BtnApplyOptional": "موافقة اختيارية",
    "BtnSetting": "إعداد"
  },
  "settingModal":{
    "msgSuccess": "",
    "titleLayer": "",
    "topDesc": "",
    "chkRequired": "",
    "chkOptional": "",
    "chkOptional2": "",
    "chkOptional3": "",
    "btnApply": "",
    "tabRequired": "",
    "tabOptional": "",
    "tabOptional2": "",
    "tabOptional3": "",
    "requiredDesc": "",
    "optionalDesc": "",
    "optional2Desc": "",
    "optional3Desc": "",
    "providerColum": "",
    "nameColum": "",
    "purposeColum": "",
    "dataColum": "",
    "typeColum": ""
  }
}

export default l10n;
