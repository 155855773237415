const l10n: I18NCookieSetLayerTextData = {

	"confirmToast":{
    "content": "NC SOFT ได้ดูแลข้อมูลส่วนตัวของท่านไว้อย่างปลอดภัย<br>ทางเราใช้คุกกี้และเทคโนโลยีการติดตามอื่นๆ เพื่อปรับปรุงประสิทธิภาพของเว็บไซต์ รวมถึงการส่งโฆษณาที่เหมาะกับความต้องการของท่าน เพื่อปรับปรุงบริการของเราอย่างต่อเนื่องโดยจัดเก็บข้อมูลเกี่ยวกับท่านเพื่อวัตถุประสงค์ในการวิเคราะห์การเข้าชมและสถิติผู้เยี่ยมชม<br>หากกดปุ่ม \"ยอมรับทั้งหมด\" จะยอมรับการบันทึกคุกกี้ในอุปกรณ์ของท่าน และหากกดปุ่ม \"ไม่ยอมรับทั้งหมด\" จะไม่บันทึกคุกกี้ทั้งหมดยกเว้นคุกกี้ที่จำเป็น<br>ท่านสามารถตรวจสอบรายละเอียดและเปลี่ยนแปลงหรือถอนความยินยอมได้ตลอดเวลาผ่าน <a href=\"#\">นโยบายคุกกี้</a>",
    "BtnApply": "ยอมรับทั้งหมด",
    "BtnDeny": "ไม่ยอมรับทั้งหมด",
		"BtnApplyOptional": "ยอมรับการเลือก",
    "BtnSetting": "ตั้งค่า"
  },
  "settingModal":{
    "msgSuccess": "",
    "titleLayer": "",
    "topDesc": "",
    "chkRequired": "",
    "chkOptional": "",
    "chkOptional2": "",
    "chkOptional3": "",
    "btnApply": "",
    "tabRequired": "",
    "tabOptional": "",
    "tabOptional2": "",
    "tabOptional3": "",
    "requiredDesc": "",
    "optionalDesc": "",
    "optional2Desc": "",
    "optional3Desc": "",
    "providerColum": "",
    "nameColum": "",
    "purposeColum": "",
    "dataColum": "",
    "typeColum": ""
  }
}
export default l10n;
