// import $ from 'jquery';
import { callGetI18NMessage, callGetCookieData } from '../service';
import { BtnApply, BtnClose, ChkOptional2Id, ChkOptional3Id, ChkOptionalId, ChkRquired, GCookieSetDimmedId, GCookieSetLayerId, getSeetingLayerTemplate, InfoOptional, InfoOptional2, InfoRequired, Infos, InfosContent, IsActive, RadioTabRequired, RadioTabs, TopDesc } from '../template/settingLayer';
import { getCookie, getUriParam, setCookie } from '../util/util2';
import { LoaderTargetPlugin } from 'webpack';
import { getCountry, getLang, getLangForL10n, getLangForL10nSettingLayer } from '../util/util';
import AbsGlobalCookie from './AbsGlobalCookie';


const TAB_REQUIRED = 'TAB_REQUIRED';
const TAB_OPTIONAL = 'TAB_OPTIONAL';
type TABS = typeof TAB_REQUIRED | typeof TAB_OPTIONAL;

export default class SettingModal extends AbsGlobalCookie {

  constructor({ zIndex = 1, apiDomain = '', contiCookieDataApi = '',}: { zIndex?: number, apiDomain?: string, contiCookieDataApi?: string,  } = {}) {
    super({ zIndex, apiDomain, contiCookieDataApi });

    this.init();
  }

  async init() {
    try {
      let lang = getLang({ onlyUsingLanguageArr: ['en', 'ja', 'de'], defaultLang: 'en' }); //  (settingLayer는 일본어, 독일어, 영어중 택1)
      // let langForL10n = getLangForL10n({ onlyUsingLanguageArr: ['ar', 'en', 'id', 'ja', 'ko', 'th', 'zh', 'ru', 'de', 'es_ES', 'es_MX', 'fr', 'it', 'pt', 'tr'], defaultLang: 'en' });
      let langForL10n = getLangForL10nSettingLayer(); //  (settingLayer는 일본어, 독일어, 영어중 택1)
      let country = getCountry();

      // 콘티에서 쿠키 데이터 가져오기 & l10n 세팅하기 (settingLayer는 일본어, 독일어, 영어중 택1)
      await Promise.all([this.getI18nMessage(langForL10n), this.getCookieData(), this.getGeoLocationCountry()]);

      // 현재 저장된 쿠키의 값 유효성 체크 - 값이 없는 경우는 유효성 체크 통과 true
      const {isValidateCookieGeoLocationInfo, isValidateCookieApplyCookie} = this.chkValidateCookie();
      !isValidateCookieGeoLocationInfo && this.deleteGeoLocationInfoAndReloadPage();
      !isValidateCookieApplyCookie && this.deleteApplyCookie();

      // GDPR 대상국가이면 동의하지 않은 쿠키 지우기
      if (this.isNowGDPRCountry) {
        this.deleteCookiesNotApplied();
      }

      // 판벌이 끝났으면(setting 은 판별 안하지만...), GEO_LOCATION_INFO 쿠키 정보를 최신 isNowGDPRCountry 정보로 덮어 써준다
      // setCookie(this.GEO_LOCATION_INFO, String(JSON.stringify({isGDPRCountry: this.isNowGDPRCountry, geoLocation: this.geoLocationCountry})), 180, '.plaync.com');
      this.setCookieByApi(this.GEO_LOCATION_INFO, String(JSON.stringify({isGDPRCountry: this.isNowGDPRCountry, geoLocation: this.geoLocationCountry})), 180);

      // [CSFDT-2320] 동의모드 추가 - applyCookie 가 있는 경우 동의 모드 쿠키에 싱크 맞춤
      if(getCookie(this.APPLY_COOKIE)){
        const applyCookieTypeArr = this.getHasBeenAppliedCookieTypes();
        const consentMode = this.getConsentModeFromApplyCookie(applyCookieTypeArr);
        await this.setCookieByApi('consentMode',  JSON.stringify(consentMode), 180);
      }

      // 레이어 템플릿
      this.setTemplate(this.l10nText.settingModal, this.cookieListAll, this.zIndex);

      // 이벤트 바인딩하고
      this.bindEvent(this.l10nText.settingModal);

      // 리셋 -> 레이어 출력하기
      this.reset();

      // window.showLayer = this.showLayer;
    } catch (err) {
      console.error(err);
    }
  }

  setTemplate(textData: SettingModalTextData, cookieData: CookieDataMap, zIndex?: number) {
    // 레이어 템플릿
    let template = getSeetingLayerTemplate(textData, cookieData, zIndex);

    // 템플릿 붙이기
    document.body.insertAdjacentHTML('beforeend', template);
  }

  // 레이어 보여주기
  showLayer = () => {
    this.displayLayer(true);
  };

  // 레이어 닫기
  hideLayer = () => {
    this.displayLayer(false);
  };

  bindEvent(textData: SettingModalTextData) {
    // 레이어 닫기 클릭
    document.addEventListener('click', (e) => {
      const delegateTarget = (e.target as HTMLElement)?.closest(`#${GCookieSetLayerId} .${BtnClose}`);
      delegateTarget && this.onClickBtnClose();
    });
    // 저장하기 클릭
    document.addEventListener('click', (e) => {
      const delegateTarget = (e.target as HTMLElement)?.closest(`#${GCookieSetLayerId} .${BtnApply}`);
      delegateTarget && this.onClickBtnApply(textData);
    });
    // 필수쿠키 허용 클릭
    document.addEventListener('click', (e) => {
      const delegateTarget = (e.target as HTMLElement)?.closest(`#${GCookieSetLayerId} .${ChkRquired}`);
      delegateTarget && this.onClickChkRquired(e);
    });
    // 탭 클릭 (필수쿠키 / 선택 쿠키)
    document.addEventListener('click', (e) => {
      const delegateTarget = (e.target as HTMLElement)?.closest(`#${GCookieSetLayerId} .${RadioTabs}`);
      delegateTarget && this.onClickRadioTabs(e);
    });
    // 딤드 클릭
    document.addEventListener('click', (e) => {
      const delegateTarget = (e.target as HTMLElement)?.closest(`#${GCookieSetDimmedId}`);
      delegateTarget && this.onClickDimmed();
    });
    // 쿠키정책(a 태크) 클릭시
    document.addEventListener('click', (e) => {
      const delegateTarget = (e.target as HTMLElement)?.closest(`#${GCookieSetLayerId} .${TopDesc} a`);
      delegateTarget && this.onClickTopDescAnchorTag(e);
    });
    // 성공메시지 확인 클릭 (메시지창 닫기) - [TODO]

  }

  // 쿠키정책(a 태크) 클릭시
  onClickTopDescAnchorTag = (e: MouseEvent) => {
    e.preventDefault();

    window.open(this.URL_COOKIE_POLICY, '_blank');
  };

  // 딤드 클릭
  onClickDimmed = () => {

    // 기존에 동의한 내용이 있다면 (쿠키가 있다면) 그대로 두고 / 없다면 필수 동의
    if (!this.hasBeenAppliedCookie) this.applyCookieOnlyRequired(location.href);

    // 레이어 닫기
    this.hideLayer();
  };

  // 레이어 닫기 클릭
  onClickBtnClose = () => {

    // 기존에 동의한 내용이 있다면 (쿠키가 있다면) 그대로 두고 / 없다면 필수 동의
    if (!this.hasBeenAppliedCookie) this.applyCookieOnlyRequired(location.href);

    // 레이어 닫기
    this.hideLayer();
  };

  // 레이어 출력/닫기
  displayLayer(flag: boolean) {
    if (flag) {
      document.getElementById(GCookieSetLayerId)?.classList.add(IsActive);
      document.getElementById(GCookieSetDimmedId)?.classList.add(IsActive);

    } else {
      document.getElementById(GCookieSetLayerId)?.classList.remove(IsActive);
      document.getElementById(GCookieSetDimmedId)?.classList.remove(IsActive);
    }
  }

  // 저장하기 클릭
  onClickBtnApply = (textData: SettingModalTextData) => {
    let applyCookieTypeArr: ApplyCookieType = [];

    applyCookieTypeArr.push('NECESSARY');
    this.isCheckedOptional && applyCookieTypeArr.push('FUNCTIONAL');
    this.isCheckedOptional2 && applyCookieTypeArr.push('PERFORMANCE');
    this.isCheckedOptional3 && applyCookieTypeArr.push('ADVERTISING');

    window.alert(textData.msgSuccess);

    this.applyCookie(applyCookieTypeArr, location.href);

    // this.hideLayer();
  };

  // 필수쿠키 허용 클릭
  onClickChkRquired = (evt: MouseEvent) => {
    evt.preventDefault();
  };

  // 탭 클릭 (필수쿠키 / 선택 쿠키)
  onClickRadioTabs = (evt: MouseEvent) => {
    let targetSelector = (evt.target as HTMLElement).dataset.target;

    this.showCookieInfoByRadioTabls(targetSelector as typeof InfoRequired | typeof InfoOptional | typeof InfoOptional2);
  };

  // 클릭된 탭에 따라 보여줄 쿠키정보 선택
  showCookieInfoByRadioTabls(targetSelector: typeof InfoRequired | typeof InfoOptional | typeof InfoOptional2) {
    const infoEls = Array.from(document.getElementsByClassName('g-cookie-info'));

    for (let infoEl of infoEls) {
      infoEl.classList.remove(IsActive);
    }

    infoEls.find(e => e.classList.contains(targetSelector))?.classList.add(IsActive);
  }

  // 리셋
  reset() {
    // 만약 파라미터에 showLayer 있으면 showLayer 실행
    getUriParam(location.href, 'showLayer') === 'true' && this.showLayer();

    // 필수 쿠키 탭클릭
    for (let El of document.getElementsByClassName(RadioTabRequired)) {
      El.dispatchEvent(new PointerEvent('click', { bubbles: true }));
    }

    // [TODO] 기존에 선택 되어 있던 쿠키에 따라 탭클릭
    this.selectRadioAsHasAppliedCookieType();
  }

  // 기존에 선택 되어 있던 쿠키에 따라 탭클릭
  selectRadioAsHasAppliedCookieType() {
    let targetArr = this.getHasBeenAppliedCookieTypes();
    if (!targetArr) return;

    targetArr.includes('FUNCTIONAL') && document.getElementById(ChkOptionalId)?.dispatchEvent(new PointerEvent('click', { bubbles: true }));
    targetArr.includes('PERFORMANCE') && document.getElementById(ChkOptional2Id)?.dispatchEvent(new PointerEvent('click', { bubbles: true }));
    targetArr.includes('ADVERTISING') && document.getElementById(ChkOptional3Id)?.dispatchEvent(new PointerEvent('click', { bubbles: true }));
  }

  // 선택쿠키 허용 체크박스 체크 여부
  get isCheckedOptional() {
    return (document.getElementById(ChkOptionalId) as HTMLInputElement).checked;
  }

  // 선택쿠키2 허용 체크박스 체크 여부
  get isCheckedOptional2() {
    return (document.getElementById(ChkOptional2Id) as HTMLInputElement).checked;

  }

  // 선택쿠키3 허용 체크박스 체크 여부
  get isCheckedOptional3() {
    return (document.getElementById(ChkOptional3Id) as HTMLInputElement).checked;

  }

  // 필수쿠키 허용 체크박스 체크 여부 : 무조건 true
  get isCheckedRequired() {
    return true;
  }


}
