const l10n: I18NCookieSetLayerTextData = {

	"confirmToast":{
    "content": "NCSOFT安全地管理您的個人資訊。<br>我司使用Cookie及其他追蹤技術來提高網站功能，同時會根據您的喜好來傳送合適的廣告，並以流量分析及訪問者統計為目的，儲存您的相關資訊以便能持續改善服務。<br>若點選「全部同意」，則表示同意在您的裝置中儲存Cookie；若點選「全部拒絕」，則除了必要Cookie外，不會儲存任何Cookie。<br>您隨時皆可透過<a href=\"#\">Cookie政策</a>來確認詳細內容，並可變更或取消同意與否選項。",
    "BtnApply": "全部同意",
    "BtnDeny": "全部拒絕",
		"BtnApplyOptional": "同意選擇",
    "BtnSetting": "設定"
  },
  "settingModal":{
    "msgSuccess": "",
    "titleLayer": "",
    "topDesc": "",
    "chkRequired": "",
    "chkOptional": "",
    "chkOptional2": "",
    "chkOptional3": "",
    "btnApply": "",
    "tabRequired": "",
    "tabOptional": "",
    "tabOptional2": "",
    "tabOptional3": "",
    "requiredDesc": "",
    "optionalDesc": "",
    "optional2Desc": "",
    "optional3Desc": "",
    "providerColum": "",
    "nameColum": "",
    "purposeColum": "",
    "dataColum": "",
    "typeColum": ""
  }
}
export default l10n;
