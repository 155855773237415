const l10n: I18NCookieSetLayerTextData = {

        "confirmToast":{
        "content": "NCSOFT kişisel bilgilerinizi güvenli bir şekilde yönetir.<br>Web sitesi performansını artırmak ve hedeflenmiş reklamlar sağlamak için çerezler ve diğer takip teknolojilerini kullanıyoruz. Trafik analizi ve ziyaretçi istatistikleri amacıyla kullanıcı ile ilgili bilgileri kaydederek sürekli olarak gelişmeyi hedefliyoruz.<br>“Tümünü Kabul Et”e tıklayarak çerezleri cihazınıza kaydetmeyi kabul ediyorsunuz. “Tümünü Reddet”e tıkladığınızda ise yalnızca çok gerekli çerezler kaydedilecektir.<br>Detayları istediğiniz zaman <a href='#'>Çerez Politikası'ndan</a> bulabilir ve anlaşma ayarlarını değiştirebilir veya geri çekebilirsiniz.",
        "BtnApply": "Tümünü Kabul Et",
        "BtnDeny": "Tümünü Reddet",
        "BtnApplyOptional": "İsteğe bağlı rıza",
        "BtnSetting": "Ayarlar"
    },
    "settingModal":{
      "msgSuccess": "",
      "titleLayer": "",
      "topDesc": "",
      "chkRequired": "",
      "chkOptional": "",
      "chkOptional2": "",
      "chkOptional3": "",
      "btnApply": "",
      "tabRequired": "",
      "tabOptional": "",
      "tabOptional2": "",
      "tabOptional3": "",
      "requiredDesc": "",
      "optionalDesc": "",
      "optional2Desc": "",
      "optional3Desc": "",
      "providerColum": "",
      "nameColum": "",
      "purposeColum": "",
      "dataColum": "",
      "typeColum": ""
    }
}
export default l10n;
