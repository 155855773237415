const l10n: I18NCookieSetLayerTextData = {
	"confirmToast":{
    "content": "NCSOFTはお客様の個人情報を安全に管理しています。<br>当社はCookieやその他追跡技術を使用し、Webサイトの性能向上を行うとともに関心のありそうな広告を送信し、トラフィック分析および閲覧者の統計を目的としたお客様に関連する情報を保存することで、サービスを改善し続けていきます。<br>「すべて同意」をクリックすると、お客様のデバイスにCookieを保存することに同意したとみなされ、「すべて拒否」をクリックすると、必須Cookie以外のすべてが保存されません。<br>いつでも<a href=\"#\">クッキーポリシー</a>から詳細内容の確認および同意の有無を変更、撤回することができます。",
    "BtnApply": "すべて同意",
    "BtnDeny": "すべて拒否",
		"BtnApplyOptional": "選択同意",
    "BtnSetting": "設定"
  },
  "settingModal":{
		"msgSuccess": "変更した設定が保存されました。",
    "titleLayer": "NCSOFT クッキー設定",
    "topDesc": "ウェブサイトをご利用になる際、 クッキーの形でブラウザに情報を保存したり、取得したりすることがあります。この情報は、お客様、お客様の嗜好、またはお客様のデバイスに関するものであり、ほとんどの場合、お客様が期待するとおりにサイトを機能させるために使用されます。この情報は通常、お客様を直接特定するものではありませんが、よりパーソナライズされたウェブ体験を提供することができます。プライバシーに関するお客様の権利を尊重するため、クッキーの種類によっては許可しないこともできます。各カテゴリーの見出しをクリックすることで、詳細を確認し、当社のデフォルト設定を変更することができます。ただし、クッキーの種類によっては、ブロックすることで、サイトの利用や提供できるサービスに影響を与える可能性があります。場合によっては、クッキーから得られたデータは第三者と共有され、カリフォルニア州消費者プライバシー法（CCPA）で定められた「個人情報の販売」とみなされる可能性があります。お客様は、クッキーを無効にすることにより、いつでもこのような共有から回避（オプトアウト）する権利を行使することができます。詳しくは クッキーポリシーをご覧ください。",
    "chkRequired": "不可欠なクッキー",
    "chkOptional": "機能性クッキー",
    "chkOptional2": "パフォーマンスクッキーまたは分析クッキー",
    "chkOptional3": "ターゲティング/広告クッキー",
    "btnApply": "設定を保存",
    "tabRequired": "Necessary cookies",
    "tabOptional": "Functional cookies",
    "tabOptional2": "Performance or Analytical Cookies",
    "tabOptional3": "Targeting / Advertising Cookies",
    "requiredDesc": "このクッキーはウェブサイトが機能するために必要なものであり、当社のシステムでは無効にすることはできません。 これらは通常、プライバシー設定の設定、ログイン、フォームへの記入など、サービスのリクエストに相当するユーザーのアクションに応じてのみ設定されます。 これらのクッキーの使用をブロックしたり警告したりするようにブラウザの設定をすることができますが、不可欠なクッキーをブロックすると、ウエブサイトの一部が正しく機能しなくなるおそれがあります。",
    "optionalDesc": "このクッキーにより、ウェブサイトは拡張機能とパーソナライズ（言語やモバイルデバイスの設定の選択など）を提供できるようになります。 これらは、当社または当社のページにサービスを追加した第三者プロバイダーによって設定される場合があります。 これらのクッキーをブロックすると、これらのサービスの一部またはすべてが正しく機能しなくなる可能性があります。",
    "optional2Desc": "このクッキーを使用すると、当社が訪問数とトラフィック ソースをカウントできるため、ウェブサイトのパフォーマンスを測定および改善できます。 これによって、どのページが最も人気があり、どのページが最も人気がないかがわかり、訪問者がウェブサイト内をどのように移動しているかを確認するのに役立ちます。 このクッキーによって収集されたこれらの情報は、まとめられます。 これらのクッキーを許可しない場合、お客様がいつ当社のウェブサイトにアクセスしたかがわからなくなり、そのパフォーマンスを監視できなくなります。",
    "optional3Desc": "これらのクッキーは、当社の広告パートナーによって当社のウェブサイトを通じて設定される場合があります。これらのクッキーは、お客様の興味・関心のプロファイルを作成し、お客様の興味・関心に関連する他のサイトに関する広告を表示するために、これらの企業によって使用される場合があります。これらのクッキーは、お客様のブラウザとインターネットデバイスを一意に識別できるよう保存されます。これらのクッキーを許可しない場合、ターゲット広告の精度が低下します。",
    "providerColum": "Provider",
    "nameColum": "Cookie name",
    "purposeColum": "Purpose",
    "dataColum": "Lifespan",
    "typeColum": "Type"
  }
}
export default l10n;
